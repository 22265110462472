<template>
  <!-- <div>TEST</div> -->
  <div class="secondo_livello_int">
    <div id="secondo_logo">
      <svgicon
        class="tva-header__logos__logo"
        name="terna-logo"
        width="120"
        :color="`var(--terna-color)`"
      />
    </div>
    <router-link :to="{ name: 'landing' }" id="secondo_X" class="secondo-close"
      >X</router-link
    >
    <div class="secondo_content" id="secondo_privacy">
      <h2>Privacy Policy</h2>
      <div id="generic" style="">
        Terna S.p.A., in qualità di Titolare del trattamento (d’ora in avanti:
        “<b>Terna</b>” o “<b>Titolare</b>”) ai sensi del Regolamento (UE)
        2016/679 (cd. Regolamento Generale per la Protezione dei Dati Personali,
        d’ora in avanti “<b>Regolamento</b>”) e del D.lgs. n. 196/2003 (cd.
        Codice in materia di protezione dei dati personali, di seguito “<b
          >Codice Privacy</b
        >”) – si impegna a proteggere la privacy online degli utenti dei propri
        siti web e Le fornisce la presente Privacy Policy allo scopo di
        informarLa, ai sensi dell'articolo 13 del Regolamento, riguardo alle
        modalità con cui i Suoi dati personali saranno trattati durante
        l'utilizzo del sito cieloterramare.terna.it, mediante la semplice
        consultazione dello stesso.
        <br />
        L'informativa è resa da Terna per l’indirizzo sopra riportato e non
        anche per altri siti web eventualmente consultati dall'utente tramite
        link presenti nel Sito (per i quali si rinvia alle rispettive
        informative/privacy policy).
        <br />
        Il trattamento dei Suoi dati personali sarà improntato ai principi di
        correttezza, liceità, trasparenza, limitazione delle finalità e della
        conservazione, minimizzazione ed esattezza, integrità e riservatezza,
        nonché al principio di responsabilizzazione di cui all’art. 5 del
        Regolamento. I Suoi dati personali verranno, pertanto, trattati in
        conformità alla normativa in materia di protezione dei dati personali e
        agli obblighi di riservatezza previsti.
        <br />
        Per “trattamento di dati personali” intendiamo qualsiasi operazione o
        insieme di operazioni, compiute con o senza l'ausilio di processi
        automatizzati e applicate a dati personali o insiemi di dati personali,
        come la raccolta, la registrazione, l'organizzazione, la strutturazione,
        la conservazione, l'adattamento o la modifica, l'estrazione, la
        consultazione, l'uso, la comunicazione mediante trasmissione, diffusione
        o qualsiasi altra forma di messa a disposizione, il raffronto o
        l'interconnessione, la limitazione, la cancellazione o la distruzione.
        <br />
        <br />
        <center>***</center>
        <br />
        <br />
        <b>1. TITOLARE E RESPONSABILE DELLA PROTEZIONE DEI DATI</b>
        <br />
        <br />
        Titolare del trattamento è Terna S.p.A., con sede legale in Roma, Viale
        E. Galbani, n. 70. Il Responsabile della protezione dei dati personali
        di Terna (<b>“Data Protection Officer”</b> o <b>“DPO”</b>) è
        contattabile mezzo e-mail, all’indirizzo: dpo@terna.it o via posta
        ordinaria, all’indirizzo sopra indicato. <br /><br />
        <b>2. I DATI PERSONALI OGGETTO DI TRATTAMENTO</b>
        <br />
        <br />
        La informiamo che i dati personali oggetto di trattamento potranno
        essere costituiti da:
        <br />
        <br />
        <b>a. Dati di navigazione</b>
        <br />
        <br />
        I sistemi informatici e le procedure software preposte al funzionamento
        del Sito acquisiscono, nel corso del loro normale esercizio, alcuni dati
        personali la cui trasmissione è implicita nell'uso dei protocolli di
        comunicazione di Internet. Si tratta di informazioni che non sono
        raccolte per essere associate a interessati identificati, ma che per
        loro stessa natura potrebbero, attraverso elaborazioni ed associazioni
        con dati detenuti da terzi, permettere di identificare gli utenti. In
        questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio
        dei computer utilizzati dagli utenti che si connettono al Sito, gli
        indirizzi in notazione URI (Uniform Resource Identifier) delle risorse
        richieste, l'orario della richiesta, il metodo utilizzato nel sottoporre
        la richiesta al server, la dimensione del file ottenuto in risposta, il
        codice numerico indicante lo stato della risposta data dal server (buon
        fine, errore, etc.) ed altri parametri relativi al sistema operativo e
        all'ambiente informatico e ai dispositivi utilizzati dall'utente per
        accedere al Sito. Questi dati vengono utilizzati al solo fine di
        ricavare informazioni statistiche anonime sull'uso del Sito, per
        controllarne il corretto funzionamento, per identificare anomalie e/o
        abusi. I dati potrebbero essere utilizzati per l'accertamento di
        responsabilità in caso di ipotetici reati informatici ai danni del Sito
        o di terzi.
        <br />
        <br />
        <b id="cookie-section">b. Cookie ed altre tecnologie di tracciamento</b>
        <br />
        <br />
        Un “cookie” è un file di testo di piccole dimensioni che può essere
        conservato in uno spazio dedicato sul disco rigido del dispositivo
        utilizzato dall’utente (ad es. computer, tablet, smartphone, ecc.) nel
        momento in cui visita un sito internet attraverso il proprio browser e
        può essere soggetto al preventivo consenso dell’utente prima di essere
        installato. Proprio grazie ai cookie, il sito ricorda le azioni e le
        preferenze dell'utente (come, ad esempio, i dati di login, la lingua
        prescelta, le dimensioni dei caratteri, altre impostazioni di
        visualizzazione, ecc.) in modo che non debbano essere indicate
        nuovamente quando questo torni a visitare il sito o navighi da una
        pagina all'altra di esso.
        <br />
        Alcune operazioni non potrebbero essere compiute senza l'uso dei cookie,
        che in certi casi sono quindi tecnicamente necessari per lo stesso
        funzionamento del sito.
        <br />
        In base alla normativa applicabile, per l'utilizzo dei cookie non sempre
        è richiesto un espresso consenso dell'utente. In particolare, non
        richiedono il consenso i cd. "cookie tecnici", indispensabili per il
        funzionamento del sito o necessari per eseguire attività richieste
        dall'utente (es. i cookie utilizzati al fine di effettuare la
        trasmissione di una comunicazione su una rete di comunicazione
        elettronica o nella misura strettamente necessaria per erogare un
        servizio esplicitamente richiesto dall'utente). Tra i cookie tecnici,
        che non richiedono un consenso espresso per il loro utilizzo, il Garante
        per la protezione dei dati personali italiano (cfr. Provvedimento
        Individuazione delle modalità semplificate per l'informativa e
        l'acquisizione del consenso per l'uso dei cookie dell'8 maggio 2014 e
        successivi chiarimenti, di seguito solo "<b>Provvedimento</b>")
        ricomprende anche:
        <br />
        <br />
        <b>•</b> i cookie analitici di prima parte e di terze parti,
        <u>
          se sono adottati strumenti che riducono il potere identificativo dei
          cookie e dell’utente e la terza parte non incrocia le informazioni
          raccolte con altre di cui già dispone,
        </u>
        utilizzati per raccogliere informazioni, in forma aggregata, sul numero
        degli utenti e su come questi visitano il sito stesso;
        <br />
        <b>•</b> i cookie di navigazione o di sessione (utilizzati per
        autenticarsi), la cui disattivazione compromette l'utilizzo dei servizi
        accessibili da login;
        <br />
        <b>•</b> i cookie di funzionalità, che permettono all'utente la
        navigazione in funzione di una serie di criteri selezionati (ad esempio,
        la lingua) al fine di migliorare il servizio reso allo stesso.
        <br />
        <br />
        Viceversa, per "cookie di profilazione", cioè quelli volti a
        identificare le preferenze dell'utente e migliorare la sua esperienza di
        navigazione, nonché per i cookie analitici di terza parte, se
        <u>non</u> sono adottati strumenti che riducono il potere identificativo
        dei cookie e la terza parte incrocia le informazioni raccolte con altre
        di cui già dispone,
        <u>è richiesto un preventivo consenso dell'utente.</u>
        <br />
        <br />

        <b>Cookie utilizzati dal sito</b>
        <span id="cookie_line"></span>
        <br />
        <br />
        <div ref="cookies_table"></div>
        <br />
        <br />
        <b>3. FINALITÀ DEL TRATTAMENTO</b>
        <br />
        <br />
        I suoi dati personali verranno trattati, con il Suo consenso ove
        necessario, per le seguenti finalità, ove applicabili:
        <br />
        <br />
        <b>3.1.</b> consentire la navigazione del Sito, l’interazione con i
        contenuti ivi presenti e l’erogazione di tutti gli altri servizi messi a
        disposizione dal Titolare, compresa la gestione della sicurezza del
        Sito.

        <br />
        <br />

        <b>3.2.</b> adempiere eventuali obblighi previsti dalle leggi vigenti,
        da regolamenti o dalla normativa comunitaria, o soddisfare richieste
        provenienti dalle autorità;
        <br />
        <br />

        <b>3.3.</b> soddisfare eventuali esigenze difensive, eventualmente
        connesse alla rilevazione, prevenzione, mitigazione e accertamento di
        attività fraudolente o illegali in relazione ai servizi forniti sul
        sito.
        <br />
        <br />
        Specifiche misure di sicurezza sono osservate per prevenire la perdita
        dei dati, usi illeciti o non corretti ed accessi non autorizzati.
        <br />
        <br />
        <b>
          4. BASE LEGALE E NATURA OBBLIGATORIA O FACOLTATIVA DEL TRATTAMENTO</b
        >
        <br />
        <br />
        La base legale del trattamento di dati personali per le finalità di cui
        alla sezione 3.1 è l’art. 6, par. 1, lett. b) del Regolamento ([…]il
        trattamento è necessario all'esecuzione di un contratto di cui
        l'interessato è parte o all'esecuzione di misure precontrattuali
        adottate su richiesta dello stesso), in quanto i trattamenti sono
        necessari all'erogazione dei servizi da Lei richiesti.
        <br />
        La finalità di cui alla sezione 3.2 rappresenta un trattamento legittimo
        di dati personali ai sensi dell’art. 6, par. 1, lett. c) del Regolamento
        ([…]il trattamento è necessario per adempiere un obbligo legale al quale
        è soggetto il titolare del trattamento). Una volta conferiti i dati
        personali, infatti, il trattamento è invero necessario per adempiere ad
        obblighi di legge a cui il Titolare è soggetto.
        <br />
        Il trattamento di cui alla sezione 3.3 è svolto per soddisfare eventuali
        esigenze difensive del Titolare ai sensi degli artt. 6.1.f) e 9.2.f) del
        Regolamento.

        <br />
        <br />

        <b>5. DESTINATARI DEI DATI PERSONALI</b>
        <br />
        <br />
        I Suoi dati personali potranno essere condivisi, per le finalità di cui
        alla sezione 3 della presente Privacy e Cookie Policy, con:
        <br />
        <br />
        5.1. persone autorizzate dal Titolare al trattamento dei dati personali
        ai sensi degli artt. 29 e 2-quaterdecies del D.Lgs 193/2003 (“Codice
        Privacy”) (ad es. il personale addetto alla gestione dei Sito, alla
        gestione dei sistemi informativi ecc.);
        <br />
        <br />
        5.2. terzi soggetti che, nell’erogazione dei servizi (a titolo
        esemplificativo: gli hosting provider ovvero soggetti delegati a
        svolgere attività di manutenzione tecnica), agiscono tipicamente in
        qualità di responsabili del trattamento ai sensi dell’art. 28 del
        Regolamento. Il Titolare conserva una lista aggiornata dei responsabili
        del trattamento nominati e ne garantisce la presa visione
        all’interessato presso la sede sopra indicata o previa richiesta
        indirizzata ai recapiti sopra indicati
        <br />
        <br />
        5.3. società del gruppo Terna aventi sede sia in UE che extra UE, in
        qualità autonomi titolari del trattamento per finalità amministrative
        sulla base del legittimo interesse ai sensi all’art. 6.1.f) e ai
        Considerando 47 e 48 del Regolamento;;
        <br />
        <br />
        5.5. soggetti, enti od autorità a cui sia obbligatorio comunicare i Suoi
        dati personali in forza di disposizioni di legge o di ordini delle
        autorità.
        <br />
        <br />
        Tali soggetti sono, di seguito, collettivamente definiti come
        “<b>Destinatari</b>”.
        <br />
        <br />
        <b>6. TRASFERIMENTI DEI DATI PERSONALI</b>
        <br />
        <br />
        Alcuni dei Suoi dati personali potrebbero essere condivisi con
        Destinatari posti al di fuori dello Spazio Economico Europeo. Il
        Titolare assicura che il trattamento sarà regolato in conformità a
        quanto previsto dal capo V del Regolamento e autorizzato in base a
        specifiche decisioni dell’Unione europea. Saranno quindi adottate tutte
        le cautele necessarie al fine di garantire la più totale protezione dei
        dati personali basando tale trasferimento: a) su decisioni di
        adeguatezza dei paesi terzi destinatari espressi dalla Commissione
        europea; b) su garanzie adeguate espresse dal soggetto terzo
        destinatario ai sensi dell’art. 46 del GDPR; c) sull’adozione di norme
        vincolanti d’impresa, cd. Corporate binding rules. È possibile avere
        maggiori informazioni, su richiesta, presso il Titolare e/o il DPO ai
        contatti sopraindicati
        <br />
        <br />
        <b>7. CONSERVAZIONE DEI DATI PERSONALI</b>
        <br />
        <br />
        I dati personali trattati per le finalità di cui alla sezione 3.1
        saranno conservati per il tempo strettamente necessario a raggiungere
        quelle stesse finalità, rispettando il principio di minimizzazione di
        cui all’art. 5, par. 1, lett. c) del GDPR.
        <br />
        I dati personali trattati per le finalità di cui alla sezione 3.2
        saranno conservati fino al tempo previsto dallo specifico obbligo o
        norma di legge applicabile.
        <br />
        In generale, il Titolare si riserva in ogni caso di conservare i Suoi
        dati per il tempo necessario ad adempiere ogni eventuale e ulteriore
        obbligo normativo cui lo stesso è soggetto o per soddisfare eventuali
        esigenze difensive.
        <br />
        Maggiori informazioni in merito al periodo di conservazione dei dati e
        ai criteri utilizzati per determinare tale periodo possono essere
        richieste inviando una richiesta scritta al Titolare ai recapiti
        indicati nella sezione "Contatti" di questa informativa.
        <br />
        <br />
        <b>8. DIRITTI DELL’INTERESSATO</b>
        <br />
        <br />
        Lei ha il diritto di accedere in qualunque momento ai dati personali che
        La riguardano, ai sensi degli artt. 15-22 del Regolamento. In
        particolare, potrà chiedere la rettifica (ex art. 16), la cancellazione
        (ex art. 17), la limitazione (ex art. 18) e la portabilità dei dati (ex
        art. 20), di non essere sottoposto a una decisione basata unicamente sul
        trattamento automatizzato, compresa la profilazione, che produca effetti
        giuridici che La riguardano o che incida in modo analogo
        significativamente sulla sua persona (ex art. 22), nonché la revoca del
        consenso eventualmente prestato (ex art. 7, par. 3).
        <br />
        Lei può formulare, inoltre, una richiesta di opposizione al trattamento
        dei Suoi Dati Personali ex art. 21 del Regolamento nella quale dare
        evidenza delle ragioni che giustifichino l’opposizione: il Titolare si
        riserva di valutare la Sua istanza, che non verrebbe accettata in caso
        di esistenza di motivi legittimi cogenti per procedere al trattamento
        che prevalgano sui Suoi interessi, diritti e libertà.
        <br />
        Le richieste vanno rivolte per iscritto al Titolare ai recapiti indicati
        nella sezione "Contatti" di questa informativa.
        <br />
        In ogni caso, lei ha sempre diritto di proporre reclamo all’autorità di
        controllo competente (Garante per la protezione dei Dati Personali), ai
        sensi dell’art. 77 del Regolamento, qualora ritenga che il trattamento
        dei suoi dati sia contrario alla normativa in vigore, ovvero di adire le
        competenti sedi giurisdizionali ai sensi dell’art. 79 del Regolamento.
        <br />
        <br />
        <b>9. MODIFICHE</b>
        <br />
        <br />
        Il Titolare si riserva di modificarne o semplicemente aggiornarne il
        contenuto, in parte o completamente, anche a causa di variazioni della
        normativa applicabile. Il Titolare La invita quindi a visitare con
        regolarità questa sezione per prendere cognizione della più recente ed
        aggiornata versione della Privacy Policy in modo da essere sempre
        aggiornato sui dati raccolti e sull’uso che ne fa Terna.
        <br />
        <br />
        <b>10. CONTATTI</b>
        <br />
        <br />
        Per esercitare i diritti di cui sopra o per qualunque altra richiesta
        può scrivere al Titolare all’indirizzo e-mail
        <a href="mailto:privacy@terna.it">privacy@terna.it</a>.
        <br />
        Può contattare altresì il Responsabile della Protezione dei Dati di
        Terna via e-mail all’indirizzo:
        <a href="mailto:dpo@terna.it">dpo@terna.it</a>.
      </div>
    </div>
  </div>
</template>

<script>
// import withPrevRoute from "@/mixins/withPrevRoute.js";

export default {
  //   mixins: [withPrevRoute],
  mounted() {
    let cookieScript = document.createElement("script");
    cookieScript.setAttribute(
      "src",
      "https://consent.cookiebot.com/51c79ab6-9432-4a4b-bf21-f544be953a18/cd.js"
    );
    cookieScript.setAttribute("data-culture", "it");
    this.$refs.cookies_table.appendChild(cookieScript);
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/external-css-privacy.scss";
</style>

<style>
a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  /* color: #fff !important; */
  
}
</style>
