<template>
  <PrivacyEn v-if="currentLanguage.toUpperCase() === 'EN'" />
  <PrivacyIt v-else-if="currentLanguage.toUpperCase() === 'IT'" />
</template>

<script>
import PrivacyEn from "@/components/PrivacyEn.vue";
import PrivacyIt from "@/components/PrivacyIt.vue";

export default {
  components: {
    PrivacyEn,
    PrivacyIt
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale;
    }
  },
  mounted() {
    if (this.$route.params.cookie) {
      document
        .getElementById("cookie-section")
        .scrollIntoView({ behavior: "smooth" });
    } else {
      window.scrollTo(0, 0);
    }
  }
};
</script>

