<template>
  <div class="secondo_livello_int">
    <div id="secondo_logo">
      <svgicon
        class="tva-header__logos__logo"
        name="terna-logo"
        width="120"
        :color="`var(--terna-color)`"
      />
    </div>
    <router-link :to="{ name: 'landing' }" id="secondo_X" class="secondo-close"
      >X</router-link
    >
    <div class="secondo_content" id="secondo_privacy">
      <h2>Privacy Policy</h2>
      <div id="generic" style="">
        Terna S.p.A., as Data Controller (hereinafter: “Terna” or the
        “Controller”), pursuant to Regulation (EU) 2016/679 (known as the
        General Data Protection Regulation", hereinafter the “Regulation”) and
        Legislative Decree No. 196/2003 (known as the Personal Data Protection
        Code, hereinafter the “Privacy Code”) undertakes to protect the online
        privacy of users of its websites and provides you with this Privacy
        Policy in order to inform you, pursuant to Art. 13 of the Regulation, on
        how your personal data will be processed when you use the website
        cieloterramare.terna.it for consultation of said site.
        <br />
        <br />
        Terna provides this information only for the above address and not for
        other websites that may be viewed by the user through links on the Site
        (in these cases please refer to the respective privacy policies).
        <br />
        <br />
        Your personal data shall be processed in compliance with the principles
        of fairness, lawfulness, transparency, limitation of purpose and
        storage, minimisation, accuracy, integrity and confidentiality and also
        with the principle of accountability pursuant to Art. 5 of the
        Regulation. Your personal data will therefore be processed in accordance
        with personal data protection legislation and the confidentiality
        obligations provided.<br />
        <br />
        “Processing of personal data” means any operation or set of operations
        performed on personal data or on sets of personal data, whether or not
        by automated means, such as collection, recording, organisation,
        structuring, storage, adaptation or alteration, retrieval, consultation,
        use, disclosure by transmission, dissemination or otherwise making
        available, alignment or combination, restriction, erasure or
        destruction.<br />
        <br />
        <br />
        <center>***</center>
        <br />
        <br />
        <b>1. DATA CONTROLLER AND DATA PROTECTION OFFICER</b>
        <br />
        <br />
        The Data Controller is Terna S.p.A., with headquarters at Viale Egidio
        Galbani 70, Rome, Italy.<br />
        The Terna (<b>“Data Protection Officer”</b> or <b>“DPO”</b>) can be
        contacted by email at dpo@terna.it or by ordinary post at the above
        address. <br />
        <br />
        <b>2. PERSONAL DATA TO BE PROCESSED</b><br />
        <br />
        We hereby inform you that the personal data to be processed may consist
        of:<br />
        <br />
        <b>a. Browsing data</b><br />
        <br />
        During normal operation, the computer systems and software procedures
        used for the functioning of the Site acquire personal data whose
        transmission is implicit in the use of internet communication protocols.
        This information is not collected in order to be associated with
        identified data subjects but may by its nature, including through
        processing and association with data held by third parties, enable the
        identification of the user. This category of data includes IP addresses
        or the domain names of the computers used by users to connect to the
        Website, URI (Uniform Resource Identifier) addresses of the requested
        resources, the time of the request, the method used to submit the
        request to the server, the size of the file obtained in response, the
        numerical code indicating the status of the response given by the server
        (successful, error, etc.) and other parameters related to the operating
        system and computer environment and to the devices used by the user to
        access the Site. This data is used exclusively to obtain anonymous
        statistical information on the use of the Site, to check its correct
        functioning and to identify anomalies and/or abuses. The data could be
        used to ascertain liability in the event of computer crimes to the
        detriment of the Site or third parties.
        <br />
        <br />
        <b id="cookie-section">b. Cookies and other tracking technologies</b
        ><br />
        <br />
        A "cookie" is a small text file that can be stored in a dedicated space
        on the hard drive of the user’s device (e.g. computer, tablet,
        smartphone, etc.) when they visit a website through their browser and
        may require the user's prior consent before being installed. Cookies
        ensure that the website remembers the user's actions and preferences
        (e.g. login details, language, font size, other display settings, etc.)
        so that they do not have to be indicated again when the user returns to
        the website or browses from one of its pages to another.
        <br />
        Some operations may not be executable without the use of cookies, which
        are therefore technically necessary in certain cases for the functioning
        of the site itself.
        <br />
        In accordance with legislation in force, the user's express consent is
        not always required for the use of cookies. In particular, consent is
        not required for technical cookies, which are essential for the
        functioning of the website or necessary to perform activities requested
        by the user (e.g. cookies used to transmit a communication on an
        electronic communications network or to the extent strictly required to
        provide a service requested explicitly by the user). Technical cookies,
        which do not require express consent for their use, as confirmed by the
        Italian Data Protection Authority (see the “Identification Measure for
        simplified procedures for information and the acquisition of consent for
        the use of cookies of 8 May 2014 and subsequent clarifications,
        hereinafter the<b>“Measure”</b>) also include:<br />
        <br />
        <b>•</b> first-party and third-party analytical cookies –
        <u
          >if tools are adopted that reduce the identifying power of the cookie
          and the user, and the third party does not cross- reference the
          information collected with other information it already possesses</u
        >
        – used to collect aggregate information on the number of users and how
        they visit the site;<br />
        <b>•</b> navigation or session cookies (used for authentication), which,
        if deactivated, will compromise the use of services accessible by
        login;<br />
        <b>•</b> functionality cookies, which allow the user to browse according
        to certain criteria (e.g. language) in order to improve the service
        provided to the user.<br />
        <br />
        However,<u> the user's prior consent is required</u> for profiling
        cookies, i.e. those aimed at identifying the user's preferences and
        improving the user's browsing experience, and for third-party analytical
        cookies, if no tools are adopted to reduce the identifying power of the
        cookies and the third party cross-references the information collected
        with other information it already possesses.
        <br />
        <br />
        <b>Cookies used by the site</b><span id="cookie_line"></span>
        <br />
        <br />
        <div ref="cookies_table"></div>
        <br />
        <br />
        <b>3. PURPOSE OF THE PROCESSING</b><br />
        <br />
        Your personal data will be processed, with your consent where necessary,
        for the following purposes, where applicable:
        <br />
        <br />
        <b>3.1.</b> to allow browsing of the Site, interaction with its content,
        and the provision of all other services made available by the
        Controller, including the management of the Site’s security;
        <br />
        <br />
        <b>3.2.</b> to fulfil any obligations under applicable laws, regulations
        or EU legislation, or comply with requests from the authorities;
        <br />
        <br />
        <b>3.3.</b> to meet any defensive requirements related to the detection,
        prevention, mitigation and investigation of fraudulent or illegal
        activities in relation to the services provided on the site.
        <br />
        <br />
        Specific security measures are taken to prevent loss, unlawful or
        incorrect use of data and unauthorised access.
        <br />
        <br />
        <br />
        <b>4. LEGAL BASIS AND MANDATORY OR OPTIONAL NATURE OF THE PROCESSING</b
        ><br />
        <br />
        The legal basis for the processing of personal data for the purposes
        referred to in section 3.1 is Art. 6, para. 1, letter b) of the
        Regulation (the processing is necessary for the performance of a
        contract to which the data subject is party or in order to take steps at
        the request of the data subject prior to entering into a contract),
        since the processing is necessary for the provision of the services you
        have requested.
        <br />
        The purpose referred to in Section 3.2 constitutes legitimate processing
        of personal data within the meaning of Art. 6, para. 1, letter c) of the
        Regulation (the processing is necessary for the fulfilment of a legal
        obligation to which the Controller is subject). Once the personal data
        has been transferred, the processing is indeed necessary for compliance
        with legal obligations that bind the Controller.
        <br />
        The processing referred to in section 3.3 is performed in order to meet
        any defensive needs of the Controller pursuant to Articles 6.1.f and
        9.2.f of the Regulation.
        <br />
        <br />
        <b>5. RECIPIENTS OF THE PERSONAL DATA</b><br />
        <br />
        Your personal data may be shared, for the purposes of section 3 of this
        Privacy and Cookie Policy, with:
        <br />
        <br />
        5.1. persons authorised by the Controller to process personal data
        pursuant to Articles 29 and 2-<i>quaterdecies</i> of Legislative Decree
        193/2003 (“Privacy Code”) (e.g. staff responsible for Site management,
        information system management, etc.);
        <br />
        <br />
        5.2. third parties who, as service providers (e.g. hosting providers or
        entities tasked with technical maintenance activities), typically act as
        data processors pursuant to Art. 28 of the Regulation. The Controller
        keeps an up-to-date list of the appointed data processors and guarantees
        that the data subject will be able to view it at the offices indicated
        above or upon request at the addresses indicated above;
        <br />
        <br />
        5.3 Terna group companies based both in the EU and outside the EU, as
        autonomous data controllers for administrative purposes on the basis of
        legitimate interest pursuant to Art. 6.1.f and Recitals 47 and 48 of the
        Regulation;
        <br />
        <br />
        5.5. persons, entities or authorities to whom your personal data is
        required to be transferred by virtue of legal provisions or orders of
        the authorities.<br />
        <br />
        Such persons are hereinafter collectively referred to as
        <b> “Recipients”</b>.
        <br />
        <br />
        <b>6. TRANSFERS OF PERSONAL DATA</b><br />
        <br />
        Some of your personal data may be shared with Recipients outside the
        European Economic Area. The Controller ensures that the processing will
        be regulated in accordance with the provisions of Chapter V of the
        Regulation and authorised on the basis of specific decisions of the
        European Union. All necessary precautions will therefore be taken in
        order to guarantee total protection for personal data, with transfers
        based on a) adequacy decisions of third country recipients expressed by
        the European Commission, b) adequate guarantees expressed by third-party
        recipients pursuant to Art. 46 of the GDPR, c) the adoption of corporate
        binding rules. Further information can be obtained upon request from the
        Controller and/or the DPO at the abovementioned addresses.<br />
        <br />
        <b>7. STORAGE OF PERSONAL DATA</b><br />
        <br />
        The personal data processed for the purposes under section 3.1. will be
        stored for the time strictly necessary to achieve said purposes, in
        compliance with the principle of minimisation set out in Art. 5, para.
        1, letter c) of the GDPR.
        <br />
        Personal data processed for the purposes under in section 3.2. will be
        stored as long as required by the specific obligation or applicable law.
        <br />
        In general, the Controller reserves the right to store your data for the
        time necessary to fulfil any further binding legal obligations or to
        meet any defence requirements. Further information on the data retention
        period and the criteria used to determine this period can be obtained by
        sending a written request to the Data Controller at the addresses shown
        in the “Contacts” section of this policy.
        <br />
        Further information on the data retention period and the criteria used
        to determine this period can be obtained by sending a written request to
        the Data Controller at the addresses shown in the “Contacts” section of
        this policy.
        <br />
        <br />
        <b>8. RIGHTS OF THE DATA SUBJECT</b><br />
        <br />
        You have the right to access personal data concerning you at any time,
        pursuant to Articles 15-22 of the Regulation. Specifically, you may
        request the rectification (Art. 16), erasure (Art. 17), restriction
        (Art. 18) and portability of data (Art. 20), or not to be subjected to a
        decision based solely on automated processing, including profiling,
        which produces legal effects concerning you or significantly affects you
        in a similar way (Art. 22), as well as the withdrawal of any consent you
        have given (Art. 7, para. 3).
        <br />
        You may also submit a request to object to the processing of your
        personal data pursuant to Art. 21 of the Regulation, in which you shall
        give evidence of the grounds for the objection. The Controller reserves
        the right to evaluate your request, which may not be accepted if there
        are legitimate reasons for the processing that prevail over your
        interests, rights and freedoms.
        <br />
        Requests should be addressed in writing to the Controller at the
        addresses shown in the “Contacts” section of this policy.
        <br />
        You have the right to lodge a complaint with the competent supervisory
        authority (Italian Data Protection Authority), pursuant to Art. 77 of
        the Regulation, if you believe that the processing of your data is
        unlawful or to take legal action pursuant to Art. 79 of the Regulation.
        <br />
        <br />
        <b>9. AMENDMENTS</b>
        <br />
        <br />
        The Controller reserves the right to amend or merely update the content,
        partially or completely, also because of changes in applicable
        legislation. The Controller therefore invites you to visit this section
        regularly in order to be aware of the most recent and updated version of
        the Privacy Policy so that you can always be fully up to date on the
        data collected and how it is used by Terna.
        <br />
        <br />
        <b>10. CONTACT DETAILS</b><br />
        <br />
        To exercise the above rights or to make any other request, please write
        to the Controller at
        <a href="mailto:privacy@terna.it">privacy@terna.it</a>.<br />
        You may also contact the Terna Data Protection Officer at:
        <a href="mailto:dpo@terna.it">dpo@terna.it</a>.
      </div>
    </div>
  </div>
</template>

<script>
// import withPrevRoute from "@/mixins/withPrevRoute.js";

export default {
  //   mixins: [withPrevRoute],
  mounted() {
    let cookieScript = document.createElement("script");
    cookieScript.setAttribute(
      "src",
      "https://consent.cookiebot.com/51c79ab6-9432-4a4b-bf21-f544be953a18/cd.js"
    );
    cookieScript.setAttribute("data-culture", "en");
    this.$refs.cookies_table.appendChild(cookieScript);
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/external-css-privacy.scss";
</style>

<style>
a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  /* color: #fff !important; */
}
</style>
